.body {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif; 
    text-align:left;
    font-weight: bold;
    padding: 30px;
}

.bage {
    background-color: beige !important;
    box-shadow: inset 0 0 20px 15px #d0c8b0;

}

.sbage{
    box-shadow:  0 0 5px 21px black;
}

.sbody {
    font-size: 18px;
    font-family: 'Playfair Display',serif;
    text-align:left;
    font-weight: 300px;
    width:82%;
}
.tbody {
    font-size: 18px;
    font-family: 'Playfair Display',serif;
    text-align:left;
    font-weight: 300px;
    width: 84%;
}




.phat {
    margin: auto;
}

.box {
    display:flex;
    box-shadow: 0pc 0px 10px 5px darkgray;
    background: content-box white;
    
}

.fbox {
    display:block;
    box-shadow: 0pc 0px 10px 5px darkgray;
    background: content-box rgb(235, 235, 211);
    }


    .min{
        width:60%;
   
    }
    

.sbox {
    display:flex;
    box-shadow: 0pc 0px 10px 5px darkgray;
    background: content-box beige;
    box-shadow: inset 0 0 35px 25px #d0c8b0;
    
    transform:scale(0.83);


}
.background {

    background-image: url(../assets/img/assets/MapS.jpg) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;    
    background-size: cover;
}

.centre {
align-items: center;
align-self: center;
text-align: center;  
overflow: hidden;
height: 9025%;

}

.wrap {
    display:block;
    font-family:'Poppins',sans-serif;
position:relative;
text-align: center;

}
.Dark {
    background-color: rgb(233, 205, 145) !important;
    height: 550px;
}


.header {

    font-size: 38px; 
    text-align: center;
    align-items: center;
}

.sheader {

text-indent: 70px;
    font-size: 28px;
}

.wrapp {
box-shadow: darkgoldenrod;
overflow: hidden;


}
.blue {
    color:darkgoldenrod;
    text-align: center;
    font-weight: bold;
    text-shadow: 0px 2px 10px darkgray;
}


.sborder {

    padding:1px;
    max-width: 340px;
    min-width: 40px;
    }

.border {

    padding:0px;
border: solid 2px gold;
max-width: 280px;
min-width: 0px;
text-align: center;


}
.flex {
    display:flex;
}

.padding {

    padding:30px;
}
.button {position: absolute;
bottom: 20px;
background-color: green;
left: 40%;
}

.sbutton {
    border:none;
    }
    
.darkMode {
    filter:invert(1)
    hue-rotate(180deg);
    background-color: white;

}
    
.normalMode{
    filter: invert(0)
    hue-rotate(0);
}

.s {
    font-weight: 700;
    color: #f96332;
}

.ind {
margin: 50px;
font-size: 21px;
text-indent: 50px;
}

.facebook {
    display: inline-block;
text-align: centre;
margin-left: 40px;
}
.whate {

    background-color: white;
}