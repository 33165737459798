.shiny {

    color:#f96332;
        font-weight: 400;

    
}

.unshift {

    color:  #f96332;
    font-weight: 400;
}

.box {
    color:black;
    font-weight: bold;
    transition: all  0.3s linear;


    &:hover { box-shadow: -5px 1px 0px 0px black;   
        font-weight: bold;  

    &:before {

        opacity: 1;
    }
}
}

.button {
    background:transparent;
    padding: 10px;
    border: none !important;
    color: white;
    transition:all 0.3s linear;
    &:hover {         background: rgba(187, 186, 186, 1);  

    &:before {

opacity:1;
    }
}
}


.sbutton {
    background:transparent;
    border: none !important;
    color: black;
    transition:all 0.3s linear;
    &:hover {         background: rgba(187, 186, 186, 1);  

    &:before {

opacity:1;
    }
}
}

.fix {
    position:fixed;
    color:black;
    background: white;
    background-color:   white  ;
    height: 100%;
    border-right: 1px solid black;
    background-color: rgba(255,255,255,.7);

}
.flex {

    display: flex;
}